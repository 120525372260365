/**
 *
 * Contributor Bio Styling
 *
**/


@import "~style/_variables.scss";

$gutter-width: 4vw;
$content-width: 600px;

.portrait {
	display: block;
	margin: auto;
	width: 70%;
}

.title-container {
  padding: 0 $gutter-width;
  max-width: $content-width;
  box-sizing: content-box;
  margin: auto;
}

.title {
	color: $indigo;
  font-size: $h3-mobile;
  margin-to: -.6em;
}

.subtitle {
  color: $slate;
	font-family: 'Libre Franklin', sans-serif;
	font-size: $h5-mobile;
	line-height: 1.2em;
	margin-bottom: 0.4em;
}

.body-container {
  padding: 0px $gutter-width;
  max-width: $content-width;
  box-sizing: content-box;
  margin: auto;
}

.label {
	color: $indigo;
  font-weight: 800;
	font-family: $franklin;
	margin-bottom: .4em;
}

.excerpt-container {
	font-weight: 500;
	margin-bottom: 1.2em;
}

.about-container {
	margin-bottom: 1.2em;
}

.display {
  text-align: center;
}

.display small {
  margin-top: .4em;
  font-size: .7em;
}

.related {
  max-width: $content-width;
  box-sizing: content-box;
  margin: 2.4em auto 2em;
}

.related-title {
  color: $indigo;
  font-size: $h3-mobile;
  text-align: center;
  margin-bottom: 1em;
}

.related li {
  width: 33.3%;
  padding: 0 2% 5%;
}

.related svg {
  font-size: 9.6px;
}

@media screen and (min-width:$bp-mobile){
  $gutter-width: 24px;

  .wrapper {
  	margin: auto;
  }

  .title-container {
    text-align: left;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

  .title {
    font-size: $h3;
  }

	.subtitle {
		font-size: $h5;
	}

  .body-container {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

	.label {
		text-align: left;
		font-size: $p;
	}

	.portrait {
		width: 300px;
	}

  .related-title {
    font-size: $h3;
  }
}

@media screen and (min-width: $bp-tablet){

  .wrapper {
    display: flex;
    justify-content: center;
  }

  .content-container {
    padding-top: 6em;
  }

}
