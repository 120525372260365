/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$helvetica-neue: "helvetica-neue", helvetica, arial, sans-serif;
$mackinac: "p22-mackinac-pro", "times new roman", serif;
$franklin: 'Libre Franklin', $helvetica-neue;

/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:             #FFF;
$warm-white:        #FAF9F8;
$indigo:            #203d51;
$indigo-tint:       #0e4254;
$slate:             #236177;
$light-gray:        #d3d3d3;

/* ============================================================ *\
|* Breakpoints (bp)
\* ============================================================ */

$bp-mobile:            450px;
$bp-mobile-large:      640px;
$bp-tablet-small:      720px;
$bp-tablet:            1000px;
$bp-monitor-small:     1200px;
$bp-monitor-medium:    1400px;

/* ============================================================ *\
 * Lengths
\* ============================================================ */

$h1-mobile:           12vw;
$h2-mobile:           10.4vw;
$h3-mobile:           8.1vw;
$h4-mobile:           7vw;
$h5-mobile:           6vw;
$h6-mobile:           5vw;
$p-mobile:            4vw;
$ps-mobile:           3.1vw;

$h1:                  54px;
$h2:                  46px;
$h3:                  36px;
$h4:                  32px;
$h5:                  27px;
$h6:                  22px;
$p:                   18px;
$ps:                  14px;
