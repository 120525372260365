/**
 *
 * Form Manager Checkbox Field
 *
**/

@import '~style/_variables.scss';

.box {
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  flex-shrink: 0;
  border: 2px solid gray;
  padding: .2em;
  transition: border-color .3s;
}

.box.active {
  border-color: black;
}

.box::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: background-color .3s;
}

.box.active::after {
  background-color: black;
}

.input {
  display: none;
}
