/**
 *
 * Contributors Styling
 *
**/


@import "~style/_variables.scss";

.role-display {
  max-width: 500px;
  margin: auto;
}

.role-container {
  margin-bottom: 4vw;
}

.role-title {
  margin-bottom: 2vw;
  text-align: center;
}

@media screen and
(min-width: $bp-tablet) {
  .role-display {
    max-width: 1070px;
  }

  .role-container {
    margin-bottom: 48px;
  }

  .role-title {
    margin-bottom: 32px;
  }
}
