/**
 *
 * Imgry
 *
**/


@import "~style/_variables.scss";

.container {
  display: inline-block;
}

.placeholder {
  position: relative;
  display: block;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}
