/**
 *
 * Footer Styling
 *
**/


@import "~style/_variables.scss";

.container {
  padding: 12vw 4vw 8vw;
}

.display {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-transform: lowercase;
}

.email-section {
  position: relative;
  height: 8.5em;
}


.sign-up-message {
  position: absolute;
  top: 0;
  left: 0;
}

.description {
  font-weight: 800;
  font-family: $franklin;
  margin-bottom: 1vw;
  display: inline-block;
}

.inputs,
.contact-methods,
.nav-links  {
  padding-left: 2vw;
}

.email {
  margin-bottom: 1vw;
}

.email input {
  color: $slate;
  border-color: $slate;

}

.submit {
  width: 100%;
  background-color: $slate;
  border-color: $slate;
}

.social-container {
  margin-top: 4vw;
  font-size: 8vw;
  fill: $slate;
}

.nav-links-container {
  margin-top: 4vw;
}

.nav-links {
  color: $slate;
}

.contact-email {
  margin-bottom: .2em;
  color: $slate;
}

.contact-email a {
  font-weight: 800;
  font-family: $franklin
}

@media screen and
(min-width: $bp-mobile) {
  .container {
    padding: 72px 24px 48px;
  }

  .description {
    margin-bottom: 6px;
  }

  .inputs,
  .contact-methods,
  .nav-links  {
    padding-left: 12px;
  }

  .email {
    margin-bottom: 12px;
  }


  .social-container {
    margin-top: 24px;
    font-size: 32px;
    fill: $slate;
  }
}

@media screen and
(min-width: $bp-tablet-small) {
  .display {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .nav-links-container {
    margin-top: 0;
  }

  .contact {
    flex-grow: 1;
    max-width: 400px;
  }
}
