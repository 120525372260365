/**
 *
 * Input Text Styling
 *
**/


@import "~style/_variables.scss";

$padding-bottom: .4em;
$padding-left: .4em;

.field {
  position: relative;
  font-family: $mackinac;
  font-size: $p-mobile;
  padding-bottom: 1.2em;
}

input.field-input {
  display: block;
  width: 100%;
  border: 0px;
  border-bottom: 2px solid $indigo;
  padding: $padding-bottom $padding-left;
  color: $indigo;
  font-family: inherit;
  font-weight: 800;
}

.field-label {
  display: block;
  position: absolute;
  line-height: 1em;
  transform: translate($padding-left, -($padding-bottom + 1.2em));
  font-family: inherit;
  transition: .3s font-size, .3s transform, .3s color;
  color: $light-gray;
  font-weight: 800;
}

.field-input:focus + .field-label,
.field-input.filled + .field-label {
  transform: translate(0px, .4em);
  font-size: $ps-mobile;
}

.optional-tag {
  position: absolute;
  right: 0;
  transform: translate(0px, .4em);
  font-size: $ps-mobile;
  color: $light-gray;
  font-weight: 800;
  line-height: 1em;
}

@media screen and
(min-width: $bp-mobile) {
  .field {
    font-size: $p;
  }

  .field-input:focus + .field-label,
  .field-input.filled + .field-label {
    font-size: $ps;
  }

  .optional-tag {
    font-size: $ps;
  }

}
