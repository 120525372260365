/**
 *
 * Skeleton Styling
 *
**/


@import "~style/_variables.scss";

.social-list {
  line-height: 0em;
}

.social-link-wrapper {
  display: inline-block;
  line-height: 0em;
}

.social-link-wrapper + .social-link-wrapper {
  margin-left: .42em;
}
