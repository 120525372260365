/**
 *
 * Banner Styling
 *
**/


@import "~style/_variables.scss";

.container {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 100;
}

.mobile-display {
  position: relative;
  z-index: 102;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: $white;
  padding: 8px 4vw 0px;
}

.mobile-logo {
  height: 48px;
  fill: $indigo;
}

.nav-display {
  background-color: white;
}

.border {
  position: absolute;
  width: 100%;
  fill: $white;
  -webkit-filter: drop-shadow( 0px 1px 3px rgba(#000, .08));
  filter: drop-shadow( 0px 1px 3px rgba(#000, .08));
  z-index: 101;
  margin-top: -1px;
}

.border svg {
  width: 100%;
}

.links-logo {
  height: 56px;
  fill: $indigo;
}

.link-item {
  font-weight: 800;
  text-transform: capitalize;
}

.social-list {
  fill: $indigo;
  display: block;
}

// Styling the nav for small screen sizes
@media screen and
(max-width: $bp-tablet-small) {
  .nav-display {
    position: fixed;
    width: 100%;
    top: -100%;
    height: 100%;
    z-index: 100;
    transform: translateY(0%);
    transition: transform .6s;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .logo-container {
    font-size: 0px;
    line-height: 1em;
  }

  .nav-display.active {
    transform: translateY(100%);
  }

  .nav-display > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
  }

  .nav-logo-display {
    display: none;
  }

  .nav-links-display {
    padding: 6vw;
    flex-grow: 1;
  }

  .link-item {
    display: block;
    font-size: $h3-mobile;
    line-height: 1em;
  }

  .link-item + .link-item {
    margin-top: .4em;
  }

  .nav-details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .nav-social-container {
    display: block;
  }

  $social-container: $warm-white;

  .nav-social-border {
    transform:translateY(1px) rotateX(180deg);
    display: block;
    fill: $social-container;
  }

  .nav-social-display {
    padding: 2vh 4vw 4vh;
    background-color: $social-container;
    text-align: center;
  }

  .social-list {
    font-size: 10.5vw;;
  }

  .social-title {
    color: $light-gray;
    font-family: $franklin;
    font-weight: 800;
    padding-bottom: .2em;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 1.2em;
    display: inline-block;
    line-height: 1em;
    font-size: $p-mobile;
  }

  @media screen and
  (min-width: $bp-mobile) {
    .nav-links-display {
      padding: 32px;
    }

    .link-item {
      font-size: $h3;
    }

    .social-list {
      font-size: 32px;
    }

    .social-title {
      font-size: $ps;
    }
  }
}



@media screen and
(min-width: $bp-tablet-small) {
  .mobile-display {
    display: none;
  }

  .nav-display {
    position: relative;
    z-index: 102;
    background-color: $white;
    padding-top: 6px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .nav-logo-display {
    line-height: 1em;
    font-size: 0px;
  }


  .nav-display > div {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .nav-details {
    flex-direction: row;
  }

  .nav-links-display {
    display: flex;
    align-items: center;
  }

  .nav-social-display {
    text-align: right;
    margin-top: 0.15em;
    margin-bottom: 0.07em;
    font-size: 20px;
  }

  .nav-social-border {
    display: none;
  }

  .nav-social-border,
  .social-title {
    display: none;
  }

  .link-item + .link-item {
    margin-left: 1.2em;
  }

}
