/**
 *
 * Home Styling
 *
**/


@import "~style/_variables.scss";

.display {
  text-align: center;
}

.display small {
  margin-top: .4em;
  font-size: .7em;
}

.contributor-container {
  padding: 0 4vw;
}

.contributor-display {
  padding-top: 2em;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.contributor-title-stories {
  margin-bottom: .4em;

  margin-top: 20px;
}

.contributor-list {
  column-count: 2;
}

.contributor-list + .contributor-list {
  padding-top: .4em;
}

.contributor-container br:last-child {
  display: none;
}

.role-title {
  font-family: $franklin;
  font-size: $ps-mobile;
}

.role-title:first-child {
  column-span: all;
}

.role-contributor {
   column-span: 1;
}


.story-wrap {
  padding: 24px;
}
.story-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 6px;
}
.story-body {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.byline {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 18px;
  
}
.story-title {
  max-width: 600px;
  font-family: $mackinac;
  fill: $indigo-tint;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
  &:hover {
    text-decoration-color: $indigo-tint;
 
    /*add those for opera and mozilla support*/
    -webkit-text-decoration-color: $indigo-tint;
    -moz-text-decoration-color: $indigo-tint;
  }
}
.story-date {
  padding-top: 6px;
}
.story-title-and-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
   .story-title {
      text-decoration-color: $indigo-tint;

      /*add those for opera and mozilla support*/
      -webkit-text-decoration-color: $indigo-tint;
      -moz-text-decoration-color: $indigo-tint;
    }
  }
}
.story-banner{
  width: 100% !important;
}
.banner-bumper {
  height: 68px;
}
.story-banner-image {
  min-height: 72px;
  max-height: 300px;
  width: 100% !important;
  color: white !important;
  display: flex;
  object-fit: cover;
  border-radius: 6px 6px 0px 0px;
  border-color: transparent;
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}
.story-profile-image{
  background-color:white;
  height: 64px;
  width: 64px;
  margin-top: 0em !important;
  margin-bottom: 0em !important;
  border-radius: 32px;
  margin-right: 14px;
}
.story-author {
  font-size: 20px;
  padding-bottom: 2px;
  font-weight: 800;
}
.story-essay {
  max-width: 640px;
}
.toc-story {
  margin-bottom: 12px;
  letter-spacing: 0.01em;
  font-size: 14px;
  padding-left: 2px;
}
.toc-story-title {
  font-weight: 800;
  margin-bottom: 2px;
  line-height: 1.2em;
}
.toc-story-author {
  font-style: italic;
}

.toc-story-list {
  height: 74vh;

  overflow: scroll;
  padding-right: 10px;
  margin-right: -10px;

}
.toc-story-list-wrap {
  position: relative;
}
.toc-story-list-mask {
  position: absolute;

  width: 100%;
  pointer-events: none;
  bottom: 0px;
  height: 120px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
}
.toc-end-of-list-bumper {
  height: 120px;
}

@media screen and
(min-width: $bp-mobile) {
  .role-title {
    font-size: $ps;
  }
}

@media screen and
(min-width: $bp-mobile-large) {
  .contributor-list {
    column-count: 3;
  }
}

@media screen and
(min-width: $bp-monitor-small) {
  .container {
    max-width: 1200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .contributor-container {
    padding-left: 24px;
    padding-right: 0px;
    width: 400px;
    box-sizing: content-box;
  }

  .contributor-title {
    text-align: left;
  }

  .contributor-display {
    padding-top: 2.6em;
    position: sticky;
    top: 32px;
  }

  .contributor-list {
    column-count: 2;
  }
}
