/**
 *
 * Skeleton Styling
 *
**/

@import '~style/_variables.scss';



:global(.youtube-video-container) {
  box-sizing: content-box;
  display: block;
  max-width: 620px;
  padding-left: 4vw;
  padding-right: 4vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

:global(.cms-button-container) {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

:global(.youtube-video-display) {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-top: 56.1%;
}

:global(.youtube-video) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: gray;
}

@media screen and
(min-width: $bp-mobile) {
  :global(.youtube-video-container) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

:global(.center) {
  text-align: center;
}

// CMS Typeography
:global(.base-content) {
  p, li, h1, h2, h3, h4, h5, h6 {
    box-sizing: content-box;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 4vw;
    padding-right: 4vw;
  }


  h1, h2, h3, h4, h5, h6 {
    margin-top: .8em;
    margin-bottom: .4em;
  }

  h1, h2, h3, h4 {
    text-align: center;
  }

  h5, h6 {
    text-align: left;
  }

  li {
    list-style: disc;
    list-style-position: inside
  }

  figure {
    text-align: center;
    width: 100%;
    margin: 0;
  }

  img {
   max-width: 100%;
   width: auto;
   margin-top: 2em;
   margin-bottom: 2em;
 }

  figure:first-child img,
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  @media screen and
  (min-width: $bp-tablet-small) {
    p, h1, h2, h3, h4, h5, h6 {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
