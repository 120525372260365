/**
 *
 * Home Styling
 *
**/


@import "~style/_variables.scss";


.content-container {
  width: 100%;
  overflow-x: hidden;
}

.content-container img {
  display: block;
  width: 114%;
  transform: translateX(-7%);
}

.page-container{
  margin-top: -10vw;
}
