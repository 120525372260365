/**
 *
 * Page Style
 *
**/

@import "~style/_variables.scss";

.page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 0;
  -webkit-overflow-scrolling: touch;
}

.page.freeze {
  overflow-y: hidden;
}
