/**
 * Input
 */

form {
  outline: none;
}

:global(.button),
button {
  outline: none;
  border: 0px solid transparent;
  background-color: $indigo;
  border-radius: 2px;
  color: white;
  border: 2px solid $indigo;
  transition: .3s color, .3s background-color;
  padding: .8em 1.6em;
  line-height: 1em;
  font-family: $mackinac;
  font-weight: 800;
}

:global(.button:hover),
:global(.button:focus),
button:hover,
button:focus {
  background-color: $warm-white;
  color: $indigo;
}

input[type=text] {
  outline: none;
  padding: .8em 1em;
  line-height: 1em;
  border-style: solid;
  border-width: 0px;
}
