/**
 *
 * Nav
 *
**/

@import '~style/_variables.scss';

$bar-height: 11px;

.container {
  position: relative;
  line-height: 0px;
  width: 44px;
  height: 44px;
}

.bar {
  position: absolute;
  width: 100%;
  display: inline-block;
  height: $bar-height;
  fill: $indigo;
  transition: .6s transform ;
}

.bar:nth-child(1) {
  transform: translateY(11px) rotate(0deg);
}

.bar:nth-child(2) {
  transform: translateY(22px) rotate(0deg);
}

.graphic {
  width: 100%;
  height: 100%;
}

.bar.flip:nth-child(1) {
  transform: translateY(22px - ($bar-height / 2)) rotate(-45deg);
}

.bar.flip:nth-child(2) {
  transform: translateY(22px - ($bar-height / 2)) rotate(225deg);
  transform-origin: center;
}
