/**
 *
 * Share Page Styling
 *
**/


@import "~style/_variables.scss";

.form {
  box-sizing: content-box;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 8vw;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.form-wrapper {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.slider {
  white-space: nowrap;
}

.form-section-wrapper {
  display: inline-block;
  width: 100%;
  margin-right: 50%;
  white-space: normal;
  vertical-align: top;
}

.form-section {
  margin-top: 4vw;
}

.avatar-image {
  max-width: 100%;
  opacity: 1;
  cursor: pointer;
  transition: opacity .3s;
}

.thanks-title {
  text-align: center;
}

.avatar-image:hover {
  opacity: .53;
}

.form-section-title {
  margin-bottom: .4em;
  color: $indigo;
}

.form-section-title.center {
  text-align: center;
  margin-bottom: 1em;
}

.back {
  cursor: pointer;
  user-select: none;
}

.story-title {
  display: inline-block;
  font-family: $franklin;
  margin-bottom: .4em;
  margin-top: 4vw;
  color: $indigo;
  font-weight: 800;
}

.story-field {
  width: 100%;
  resize: none;
  height: 8em;
  border: 2px solid $indigo;
  padding: .8em 1em;
  font-weight: 800;
  font-family: $mackinac;
}

.story-field::placeholder {
  color: $light-gray;
}

.submit-container {
  margin-top:  4vw;
}

.submit {
  width: 100%;
}

@media screen and
(min-width: $bp-mobile) {
  .form {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 48px;
  }

  .form-section {
    margin-top: 24px;
  }

  .story-title {
    margin-top: 24px;
  }

  .submit-container {
    margin-top: 4vw;
    text-align: right;
  }

  .submit {
    width: auto;
  }
}

@media screen and
(min-width: $bp-mobile-large) {

  .contact-info,
  .split
   {
    display: flex;
    justify-content: space-between;
  }

  .contact-info > *,
  .split > *  {
    width: 48%;
  }
}
