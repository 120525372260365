/*
 * App Style
 *
 * The base style for the application.
 *
 */
@charset "utf-8/index";

@import '_variables.scss';
@import '_base.scss';
@import '_typeography.scss';
@import '_input.scss';
@import '_cms.scss';

.app {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
//	font-size: $h4;
	overflow: hidden;
}
