/**
 *
 * Page Style
 *
**/

@import "~style/_variables.scss";

.display {
  padding-top: 4vw;
}

.display {
  position: relative;
  padding-top: 96px;
  padding-bottom: 48px;
  z-index: 2;
  background-color: $white;
}

.border-container {
  position: absolute;
  width: 100%;
  fill: $white;
  -webkit-filter: drop-shadow( 0px 1px 3px rgba(#000, .08));
  filter: drop-shadow( 0px 1px 3px rgba(#000, .08));
}

@media screen and
(min-width: $bp-tablet-small) {

  .container {
    position: relative;
  }

  .display {
    padding-top: 96px;
    padding-bottom: 48px;
    z-index: 2;
  }

  .border-container svg {
    width: 100%;
  }
}
