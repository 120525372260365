/**
 *
 * Contributor List Styling
 *
**/


@import "~style/_variables.scss";

.display {
  text-align: center;
}

.portrait {
  width: 100%;
  height: auto;
}

.contributor {
  display: inline-block;
  width: 50%;
  padding: 0 4% 8%;
  position: relative;
  margin-bottom: 1em;
}

.display br {
  display: none;
}

.name {
  width: 100%;
  height: auto;
  font-size: 9px;
  letter-spacing: .04em;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: $mackinac;
  fill: $indigo-tint;
  font-weight: 800;
}

.contributor:last-child {
  display: none;
}

@media screen and
(max-width: $bp-tablet) {
  .display:not(.no-break) {
    br:nth-of-type(2n) {
      display: inline;
    }
  }
}

@media screen and
(min-width: $bp-tablet) and
(max-width: $bp-monitor-small) {
  .contributor {
    width: 250px;
    padding: 0px 30px 36px;
  }

  .display:not(.no-break) {
    br:nth-of-type(5n) {
      display: inline;
    }

    br:nth-of-type(5n+3):nth-last-of-type(5n+5),
    br:nth-of-type(5n+3):nth-last-of-type(5n+2),
    br:nth-of-type(5n+3):nth-last-of-type(5n+1) {
      display: inline;
    }

    br:nth-of-type(5n+2):nth-last-of-type(5n+5),
    br:nth-of-type(5n+2):nth-last-of-type(5n+4) {
      display: inline;
    }


    .contributor:nth-of-type(5n + 5):last-child,
    .contributor:nth-of-type(5n + 7):last-child {
      display: inline-block;
      width: 250px;
    }
  }

}

@media screen and
(min-width: $bp-monitor-small) {
  .contributor {
    width: 250px;
    padding: 0px 30px 36px;
  }
  .display:not(.no-break) {
    br:nth-of-type(7n) {
      display: inline;
    }

    br:nth-of-type(7n+4):nth-last-of-type(7n+4),
    br:nth-of-type(7n+4):nth-last-of-type(7n+3),
    br:nth-of-type(7n+4):nth-last-of-type(7n+2),
    br:nth-of-type(7n+4):nth-last-of-type(7n+1) {
      display: inline;
    }

    br:nth-of-type(7n+3):nth-last-of-type(7n+7),
    br:nth-of-type(7n+3):nth-last-of-type(7n+6),
    br:nth-of-type(7n+3):nth-last-of-type(7n+5) {
      display: inline;
    }


    .contributor:nth-of-type(7n + 7 ):last-child,
    .contributor:nth-of-type(7n + 10):last-child {
      display: inline-block;
      width: 250px;
    }

    .contributor:nth-of-type(7n + 6):last-child,
    .contributor:nth-of-type(7n + 9):last-child {
      display: inline-block;
      width: 500px;
    }
  }
}
