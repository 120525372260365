/**
 *
 * Home Styling
 *
**/


@import "~style/_variables.scss";

.display {
  text-align: center;
}

.display small {
  margin-top: .4em;
  font-size: .7em;
}

.contributor-container {
  padding: 0 4vw;
}

.contributor-display {
  padding-top: 2em;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.contributor-title {
  margin-bottom: .4em;
  text-align: center;
}

.contributor-list {
  column-count: 2;
}

.contributor-list + .contributor-list {
  padding-top: .4em;
}

.contributor-container br:last-child {
  display: none;
}

.role-title {
  font-family: $franklin;
  font-size: $ps-mobile;
}

.role-title:first-child {
  column-span: all;
}

.role-contributor {
   column-span: 1;
}

@media screen and
(min-width: $bp-mobile) {
  .role-title {
    font-size: $ps;
  }
}

@media screen and
(min-width: $bp-mobile-large) {
  .contributor-list {
    column-count: 3;
  }
}

@media screen and
(min-width: $bp-monitor-small) {
  .container {
    max-width: 1200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .contributor-container {
    padding-left: 24px;
    padding-right: 0px;
    width: 400px;
    box-sizing: content-box;
  }

  .contributor-title {
    text-align: left;
  }

  .contributor-display {
    padding-top: 2.6em;
    position: sticky;
    top: 32px;
  }

  .contributor-list {
    column-count: 2;
  }
}
