/**
 *
 * Skeleton Styling
 *
**/

@import '~style/_variables.scss';

:global(.youtube-video-container) {
  box-sizing: content-box;
  display: block;
  max-width: 640px;
  padding-left: 4vw;
  padding-right: 4vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

:global(.cms-button-container) {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

:global(.youtube-video-display) {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-top: 56.1%;
}

:global(.youtube-video) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: gray;
}

@media screen and
(min-width: $bp-mobile) {
  :global(.youtube-video-container) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
