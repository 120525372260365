/**
 *
 * Base Styling
 *
**/

@import '~style/_variables.scss';

.display {
  padding: 8vw 0px;
}

@media screen and
(min-width: $bp-mobile) {
  .display {  
    padding: 48px 0px;
  }
}
